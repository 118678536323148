import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import SignaturePad from "react-signature-canvas";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Textarea,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  Input,
  Button,
  VStack,
  Radio,
  CheckboxGroup,
  Checkbox,
  HStack,
  Flex,
  Text,
  Spinner,
  Icon,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { BsXCircle } from "react-icons/bs";
import { VscWarning } from "react-icons/vsc";

const schema = yup.object().shape({
  businessName: yup.string().required("Business Name is required"),
  businessPhysicalLocation: yup
    .string()
    .required("Business Physical Address is required"),
  businessMailingAddress: yup.string().required("Business Mailing Address is required"),
  businessAddressCity: yup.string().required("City is required"),
  businessAddressState: yup.string().required("State is required"),
  businessAddressZip: yup.string().required("Zip is required"),
  ownerName: yup.string().required("Owner Name is required"),
  primaryNumber: yup.string().required("Primary Number is required"),
  typeOfOwnership: yup.string().required("Type of Ownership is required").nullable(),
  businessDescription: yup.string().required("Business Description is required"),
  underSignedTaxes: yup.array().required("Applicable taxes is required").nullable(),
  taxRemitted: yup.string().required("Tax Remitted is required").nullable(),
  applicantSignature: yup
    .string()
    .required("Signature is required.")
    .matches(
      /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
      "Signature must be png"
    ),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
});

const errorStyles = {
  color: "#bf1650",
  fontSize: "1rem",
};

function RequiredSpan() {
  return <span style={{ color: "#bf1650" }}>*</span>;
}


const NonPropertySalesTaxPermit = () => {


  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const watchData = watch();

  // create a Ref to access our form element
  const formRef = useRef(null);

  const FormSuccess = () => (
    <Box my="20">
    <Alert status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      
      p="4"
      >
    <AlertIcon boxSize='40px' mr={0}/>
      <AlertTitle mb="4">
        Application submitted successfully!
        </AlertTitle>
        <AlertDescription maxWidth='md'>
        Thank you for submitting your application. We will be in touch with you shortly.<br /><br />Please check your email for a copy of your application for your records.
  </AlertDescription>
    </Alert>
  </Box>
   )
  
   const scrollTop = () => {
    window.scrollTo(0, 0);
  };
   

   const sendMailgunFormData = async (data) => {
    //event.preventDefault()
    setProcessing(true);

    if (!formRef.current) {
        console.log('something went wrong with the ref');
    }

    const formData = new FormData(formRef.current);
    
    // convert signature to blob
    const applicantSignatureBlob = await (await fetch(watchData.applicantSignature)).blob([applicantSignatureBlob], "applicantSignature.png");

    // append blob to formData
    formData.append("applicantSignature", applicantSignatureBlob, "applicantSignature.png");

    const convertArrayToObject = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
          return {
            ...obj,
            [item[key]]: item,
          };
        }, initialValue);
      };

    // Convert license array to object - parsing function only returns the last value
    {watchData.underSignedTaxes && (
      convertArrayToObject(watchData.underSignedTaxes, "Taxes")
    )}

    {watchData.underSignedTaxes && (
      formData.append('Taxes', JSON.stringify(watchData.underSignedTaxes))
    )}
    

    // Use console.log() to see the data that is being sent to the server
    console.log(Array.from(formData.entries()));

   fetch('/.netlify/functions/send-non-property-sales-tax', {
           method: 'POST',
           body: formData,
           // don't send headers due to boundary problem 
       })
       // Convering the response to JSON breaks status code
       .then(res => {
           console.log(res)
              if (res.status === 200) {
                    setSucceeded(true)
                    setProcessing(false)
                    reset()
                    scrollTop()
                } else {
                    setSucceeded(false)
                    console.log("network error")
                    alert("Network error. Please try again later.")
                }
       }
    )
};

  {
    /* Signature Pad */
  }
  let sigCanvas = useRef({});

  const formatIntoPng = () => {
    if (sigCanvas.current) {
      const dataURL = sigCanvas.current.toDataURL();
      return dataURL;
    }
  };

  const clear = () => sigCanvas.current.clear();

  console.log(watchData)

  return (
      <Box id="theForm">
        {succeeded ? (
          <FormSuccess />
        ) : (
          <>
            <form onSubmit={handleSubmit(sendMailgunFormData)} ref={formRef}>
              <Box textAlign="left" mb="8" w="100%">
                <FormControl>
                  <FormLabel>Name of Business<RequiredSpan /></FormLabel>
                  <Input
                    type="text"
                    {...register("businessName")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.businessName && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.businessName?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </FormControl>
              </Box>

              <Box textAlign="left" mb="8" w="100%">
                <FormControl>
                  <FormLabel>Physical Location of Business<RequiredSpan /></FormLabel>
                  <Input
                    type="text"
                    {...register("businessPhysicalLocation")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.businessPhysicalLocation && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.businessPhysicalLocation?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </FormControl>
              </Box>

              <Box textAlign="left" mb="4" w="100%">
                <FormControl>
                  <FormLabel>Mailing Address of Business<RequiredSpan /></FormLabel>
                  <Input
                    type="text"
                    {...register("businessMailingAddress")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.businessMailingAddress && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.businessMailingAddress?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </FormControl>
              </Box>

              <Flex w="100%" flexWrap="wrap`">
                <Box mb="8" w={{ base: "100%", md: "33.33%" }}>
                  <FormControl>
                    <FormLabel>City<RequiredSpan /></FormLabel>
                    <Input
                      type="text"
                      {...register("businessAddressCity")}
                      autoComplete="on"
                    />
                    <p style={errorStyles}>
                      {errors.businessAddressCity && (
                        <HStack spacing="5px">
                          <VscWarning />{" "}
                          <Box>{errors.businessAddressCity?.message}</Box>
                        </HStack>
                      )}
                    </p>
                  </FormControl>
                </Box>
                <Box mb="8" w={{ base: "100%", md: "33.33%" }} pl={{ base: 0, md: "4" }}>
                  <FormControl>
                    <FormLabel>State<RequiredSpan /></FormLabel>
                    <Input
                      type="text"
                      {...register("businessAddressState")}
                      autoComplete="on"
                    />
                    <p style={errorStyles}>
                      {errors.businessAddressState && (
                        <HStack spacing="5px">
                          <VscWarning />{" "}
                          <Box>{errors.businessAddressState?.message}</Box>
                        </HStack>
                      )}
                    </p>
                  </FormControl>
                </Box>
                <Box mb="8" w={{ base: "100%", md: "33.33%" }} pl={{ base: 0, md: "4" }}>
                  <FormControl>
                    <FormLabel>Zip<RequiredSpan /></FormLabel>
                    <Input
                      type="text"
                      {...register("businessAddressZip")}
                      autoComplete="on"
                    />
                    <p style={errorStyles}>
                      {errors.businessAddressZip && (
                        <HStack spacing="5px">
                          <VscWarning />{" "}
                          <Box>{errors.businessAddressZip?.message}</Box>
                        </HStack>
                      )}
                    </p>
                  </FormControl>
                </Box>
              </Flex>

              <Flex w="100%" flexWrap="wrap">
                <Box mb="8" w={{ base: "100%", md: "50%" }}>
                  <FormLabel>Name of Owner<RequiredSpan /></FormLabel>
                  <Input
                    type="text"
                    {...register("ownerName")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.ownerName && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.ownerName?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box
                  mb="8"
                  w={{ base: "100%", md: "50%" }}
                  pl={{ base: 0, md: "4" }}
                >
                  <FormLabel>Primary Number<RequiredSpan /></FormLabel>
                  <Input
                    type="tel"
                    {...register("primaryNumber")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.primaryNumber && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.primaryNumber?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

              <Box textAlign="left" mb="8" w="100%">
                <FormControl>
                  <FormLabel>Email<RequiredSpan /></FormLabel>
                  <Input
                    type="email"
                    {...register("email")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.email && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.email?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </FormControl>
              </Box>

              <Box mb="8">
                <FormControl>
                  <FormLabel as="legend">Type of Ownership:<RequiredSpan /></FormLabel>
                  <RadioGroup>
                    <HStack align="left">
                      <Radio
                        value="Sole Proprietorship"
                        name="typeOfOwnership"
                        {...register("typeOfOwnership")}
                      >
                        Sole Proprietorship
                      </Radio>
                      <Radio
                        value="Corporation"
                        name="typeOfOwnership"
                        {...register("typeOfOwnership")}
                      >
                        Corporation
                      </Radio>
                      <Radio
                        value="Partnership"
                        name="typeOfOwnership"
                        {...register("typeOfOwnership")}
                      >
                        Partnership
                      </Radio>
                      <Radio value="Other" name="typeOfOwnership" {...register("typeOfOwnership")}>
                        Other
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>
                <p style={errorStyles}>
                    {errors.typeOfOwnership && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.typeOfOwnership?.message}</Box>
                      </HStack>
                    )}
                  </p>
              </Box>

              <Box textAlign="left" mb="8" w="100%">
                <FormControl>
                  <FormLabel>Description of Business<RequiredSpan /></FormLabel>
                  <Input
                    type="text"
                    {...register("businessDescription")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.businessDescription && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.businessDescription?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </FormControl>
              </Box>

              {/* <Box
                border="1px"
                borderColor="black.500"
                borderRadius="10"
                mb="8"
                maxW="container.xl"
                p="20px"
              >
                <Box>
                  <FormLabel>
                    <Text as="u" display="block">
                      SHORT TERM RENTAL OWNERS:
                    </Text>
                    Please list any websites where you advertise your rental
                    (VRBO, Airbnb, FlipKey, property management companies, etc.)
                  </FormLabel>
                  <Textarea
                    size="sm"
                    {...register("additionalInformation")}
                    autoComplete="on"
                  />
                  {errors.additionalInformation && (
                    <HStack spacing="5px" style={errorStyles}>
                      <VscWarning />{" "}
                      <Box>{errors.additionalInformation?.message}</Box>
                    </HStack>
                  )}
                </Box>
                <Text fontWeight="500" mt="2">
                  *As the property owner, it is your responsibility to ensure
                  taxes are being paid and submitted to the City of Driggs.
                </Text>
              </Box> */}

              <Box textAlign="left" mb="8">
                <FormControl>
                  <FormLabel as="legend">
                    The undersigned agrees to collect the following applicable
                    taxes (check all that apply):<RequiredSpan />
                  </FormLabel>
                  <CheckboxGroup defaultValue="" w="100%">
                    <VStack direction="column" w="full">
                      <Checkbox
                        w="100%"
                        value="0.5%"
                        name="underSignedTaxes"
                        {...register("underSignedTaxes")}
                      >
                        One-half percent (0.5%) on all Retail Sales{" "}
                        <Text fontSize="sm">
                          (all retail sales subject to taxation not included in
                          the following three categories)
                        </Text>
                      </Checkbox>
                      <Checkbox
                        w="100%"
                        value="1%"
                        name="underSignedTaxes"
                        {...register("underSignedTaxes")}
                      >
                        One percent (1%) on all Prepared Foods{" "}
                        <Text fontSize="sm">
                          (furnishing, preparing or serving food, meals or
                          drinks directly consumed)
                        </Text>
                      </Checkbox>
                      <Checkbox
                        w="100%"
                        value="2%"
                        name="underSignedTaxes"
                        {...register("underSignedTaxes")}
                      >
                        Two percent (2%) on all Alcohol by the Drink{" "}
                        <Text fontSize="sm">
                          (alcohol consumed on premises)
                        </Text>
                      </Checkbox>
                      <Checkbox
                        w="100%"
                        value="3%"
                        name="underSignedTaxes"
                        {...register("underSignedTaxes")}
                      >
                        Six percent (6%) on all Lodging{" "}
                        <Text fontSize="sm">
                          (Hotels, Motels, Bed and Breakfast, Short Term
                          Rentals, etc.)
                        </Text>
                      </Checkbox>
                    </VStack>
                  </CheckboxGroup>
                </FormControl>
                <p style={errorStyles}>
                  {errors.underSignedTaxes && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box> {errors.underSignedTaxes?.message}</Box>{" "}
                    </HStack>
                  )}
                </p>
              </Box>

              <Box textAlign="left" mb="8">
                <FormControl>
                  <FormLabel as="legend">
                    Tax will be remitted for each calendar:<RequiredSpan />
                  </FormLabel>
                  <RadioGroup defaultValue="" w="100%">
                    <VStack direction="column" w="full">
                      <Radio
                        w="100%"
                        value="Month"
                        name="taxRemitted"
                        {...register("taxRemitted")}
                      >
                        Month
                      </Radio>
                      <Radio
                        w="100%"
                        value="Quarter"
                        name="taxRemitted"
                        {...register("taxRemitted")}
                      >
                        Quarter
                      </Radio>
                      <Radio
                        w="100%"
                        value="Year"
                        name="taxRemitted"
                        {...register("taxRemitted")}
                      >
                        Year
                      </Radio>
                    </VStack>
                  </RadioGroup>
                </FormControl>
                <p style={errorStyles}>
                  {errors.taxRemitted && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box> {errors.taxRemitted?.message}</Box>{" "}
                    </HStack>
                  )}
                </p>
              </Box>

              <Text mb="8" fontWeight="500">
                Taxes are due on or before the 20th day of the succeeding month
                to the City Clerk's Office, PO Box 48, Driggs, ID 83422.
              </Text>

              <Text mb="8" fontWeight="500">
                The undersigned hereby makes application for a Municipal
                Non-Property Sales Tax Permit as required under Ordinance No.
                450-22 of the City of Driggs, Idaho and agrees to remit the
                above municipal tax{" "}
                <Text as="i">
                  <Text as="u">
                    using the same schedule as required for remittance of taxes
                    to the Idaho State Tax Commission
                  </Text>
                </Text>
                .
              </Text>

              <Flex w="100%" flexWrap="wrap">
                <Box mb="8" w={{ base: "100%", md: "70%" }}>
                  <FormControl
                    isInvalid={!!errors?.applicantSignature?.message}
                    errorText={errors?.applicantSignature?.message}
                    isRequired
                  >
                    <FormLabel>Applicant Signature</FormLabel>
                    <Box>
                      <Controller
                        name="applicantSignature"
                        control={control}
                        render={({ field }) => (
                          <SignaturePad
                            ref={sigCanvas}
                            onEnd={() =>
                              field.onChange(
                                formatIntoPng(sigCanvas.current.toDataURL())
                              )
                            }
                            penColor="black"
                            canvasProps={{
                              style: {
                                border: "1px solid #e2e8f0",
                                borderRadius: "0.375rem",
                                width: "100%",
                                height: "200px",
                                backgroundColor: "white",
                              },
                            }}
                          />
                        )}
                      />
                      <p style={errorStyles}>
                        {errors.applicantSignature && (
                          <HStack spacing="5px">
                            <VscWarning /> <Box>{errors.applicantSignature?.message}</Box>
                          </HStack>
                        )}
                      </p>
                    </Box>
                    <Box>
                      <input
                        type="hidden"
                        value={new Date()}
                        name="timestamp"
                        {...register("timestamp", { required: true })}
                      />
                    </Box>
                  </FormControl>
                  <Box my="2" textAlign="right">
                    <Button size="sm" onClick={clear} colorScheme="red">
                      <Icon as={BsXCircle} mr="2" />
                      Clear Signature
                    </Button>
                  </Box>
                </Box>
              </Flex>

              <Text fontWeight="500" mb="8">
                THIS PERMIT IS NONTRANSFERABLE BY SALE, LEASE, ASSIGNMENT OR OTHERWISE.
              </Text>

              <Box mb="12">
                <Button type="submit" textAlign="left">
                  {processing ? (
                    <Spinner />
                  ) : succeeded ? (
                    "Submitted!"
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </form>
          </>
        )}
      </Box>
  );
};

export default NonPropertySalesTaxPermit;
